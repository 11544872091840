.Home {
  text-align: center;
  background-color: #282c34;
  padding: 0 8%;
}

.Home-container {
  width: 100%;
  height: 100%;
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 800px;
  margin: 0 auto;
}

.Home-header {
  font-size: calc(16px + 4vmin);
  color: white;
  display: flex;
  align-items: flex-start;
}

.Home-body {
  font-size: calc(8px + 2vmin);
  color: white;
  display: flex;
  align-items: flex-start;
  text-align: left; /* Add this line */
}

.Home-footer {
  font-size: calc(8px + 1vmin);
  color: white;
  display: flex;
  align-items: flex-start;
  border-top: 1px solid white;
  justify-content: space-between;
  padding-top: 1vh;
  padding-bottom: 5%;
}

.Button-container {
  margin-top: 4vh;
  display: flex;
}

.Button {
  align-items: center;
  background: #ffffff;
  border: 0 solid #e2e8f0;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  color: #282c34;
  display: inline-flex;
  font-family: Inter, sans-serif;
  font-size: calc(8px + 2vmin);
  font-weight: 700;
  height: calc(2 * (12px + 2vmin));
  justify-content: center;
  line-height: 1rem;
  overflow-wrap: break-word;
  padding: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
  text-decoration: none;
  width: auto;
  border-radius: 2rem;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

